import { buffers, eventChannel, END } from 'redux-saga';
import { takeEvery, put, call, take } from 'redux-saga/effects';
import { uploadFile } from '../../client/app/api/backend';
import {
	fileUploadStart,
	fileUploadSuccess,
	fileUploadError,
	fileUploadProgress,
	fileUploadCancelled,
} from '../reducers/fileReducer/fileActions';

export default function* fileUploadFlow() {
	yield takeEvery('FILE_UPLOAD_REQUEST', fileUploadSaga);
}

export function* fileUploadSaga(action) {
	const { fileEntry, uid } = action;
	yield put(fileUploadStart(fileEntry.name, uid));
	const chan = yield call(createUploadFileChannel, fileEntry, uid);
	yield takeEvery(chan, fileUploadWorker);

	function* fileUploadWorker({ progress = 0, error, success, ...rest }) {
		if (error) {
			yield put(fileUploadError(fileEntry.name, error, uid));
			return;
		}
		if (success) {
			const { originalId, thumbnailId } = rest.response.data;
			if (thumbnailId !== undefined) {
				yield put(fileUploadSuccess(fileEntry.name, originalId, thumbnailId, uid));
			} else yield put(fileUploadSuccess(fileEntry.name, originalId, uid));
			return;
		}
		yield put(fileUploadProgress(fileEntry, progress, uid));
	}

	yield take(({ type, name }) => type === 'FILE_UPLOAD_CANCEL' && name === fileEntry.name);
	chan.close();
	yield put(fileUploadCancelled(fileEntry.name, uid));
}

export function createUploadFileChannel(fileEntry, uid) {
	return eventChannel(emitter => {
		const onProgress = event => {
			if (event.lengthComputable) {
				const progress = event.loaded / event.total;
				emitter({ progress });
			}
		};
		const onError = () => {
			emitter({ error: new Error('Upload failed') });
			emitter(END);
		};
		const onSuccess = response => {
			emitter({ success: true, response });
			emitter(END);
		};
		return uploadFile(fileEntry, onProgress, onSuccess, onError, uid);
	}, buffers.sliding(2));
}
