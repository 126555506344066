import React from 'react';

import './loading.scss';
import '/client/app/styles/loading.scss';

interface IProps {
	width?: string;
	height?: string;
}

export default function Loading(props: IProps) {
	const { width, height } = props;
	return (
		<div style={{ width, height }} className="loading loadingIndicator">
			<div />
		</div>
	);
}
