import ReducerConstants from './communitySearchActions';

export const defaultState = {
	value: '',
	error: false,
};
const communitySearchReducer = function communitySearchReducerFn(state, action) {
	if (state === undefined) {
		// eslint-disable-next-line no-param-reassign
		state = defaultState;
		return state;
	}
	switch (action.type) {
		case ReducerConstants.COMMUNITY_SEARCH_VALUE_UPDATE: {
			return {
				...state,
				value: action.value,
			};
		}
		case ReducerConstants.COMMUNITY_SEARCH_ERROR_SET: {
			return {
				...state,
				error: action.error,
			};
		}
		default:
			return state;
	}
};
export default communitySearchReducer;
