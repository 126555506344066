const ReducerConstants = {
	LOGIN_ATTEMPT: 'LOGIN_ATTEMPT',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
	LOGIN_FAILURE: 'LOGIN_FAILURE',
	LOGIN_FORM_VALUES_UPDATED: 'LOGIN_FORM_VALUES_UPDATED',
};

export const loginFormLoginAttempt = () => ({
	type: ReducerConstants.LOGIN_ATTEMPT,
});

export const loginFormLoginSuccess = () => ({
	type: ReducerConstants.LOGIN_SUCCESS,
});

export const loginFormLogoutSuccess = () => ({
	type: ReducerConstants.LOGOUT_SUCCESS,
});

export const loginFormLoginFailure = error => ({
	type: ReducerConstants.LOGIN_FAILURE,
	error,
});

export const loginFormValuesUpdated = (email, password) => ({
	type: ReducerConstants.LOGIN_FORM_VALUES_UPDATED,
	email,
	password,
});

export default ReducerConstants;
