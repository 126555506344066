const ReducerConstants = {
	UPDATE_COMMUNITIES: 'UPDATE_COMMUNITIES',
};

export const updateCommunities = communities => ({
	type: ReducerConstants.UPDATE_COMMUNITIES,
	communities,
});

export default ReducerConstants;
