import React from 'react';
import PropTypes from 'prop-types';
import isFunction from '/client/app/util/isFunction';

type IProps = {
	hidden: boolean;
	children: Array<any> | any;
};

export default function Hideable(props: IProps) {
	const { hidden, children } = props;

	if (hidden) return <></>;

	return isFunction(children) ? children() : children;
}

Hideable.propTypes = {
	hidden: PropTypes.bool.isRequired,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.any]).isRequired,
};
