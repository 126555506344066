import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import Status from './status';
import { SESSION_USER_WITH_ACCOUNTS } from '../graphql/queries/user';
import Loading from '../components/loading/loading';

interface Group {
	_id: String;
	name: String;
	builtin: Boolean;
}

export default function AdminOnly(BaseComponent: any) {
	function Restricted(props: any) {
		const { data, loading } = useQuery(SESSION_USER_WITH_ACCOUNTS);

		function isAdmin() {
			if (!loading && data && data.sessionUser)
				return !!data.sessionUser.memberOf.find((group: Group) => group.name === 'Site Admins' && group.builtin);
			return false;
		}

		if (loading) return <Loading />;

		if (isAdmin()) {
			// @ts-ignore
			return <BaseComponent {...props} />;
		}
		return (
			<Status code={403}>
				<div className="mainTitle">forbidden</div>
				<div className="fourOhFour">this page requires admin privileges to view</div>
			</Status>
		);
	}

	return Restricted;
}
