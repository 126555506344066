import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const TRANSACTIONS_FOR_USER = gql`
	query transactions($count: Int, $skip: Int) {
		transactionsForUser(count: $count, skip: $skip) {
			to
			from
			date
			balanceBefore
			balanceAfter
			amount
			description
			status
			type
			_id
		}
	}
`;
