import gql from 'graphql-tag';

export const SET_THEME = gql`
	mutation setTheme($theme: String!) {
		setTheme(theme: $theme) {
			_id
			theme
		}
	}
`;

export const SET_FEED_VIEW_MODE = gql`
	mutation setFeedViewMode($feedViewMode: String!) {
		setFeedViewMode(feedViewMode: $feedViewMode) {
			_id
			feedViewMode
		}
	}
`;
