import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { SESSION_USER_WITH_ACCOUNTS } from '/client/app/graphql/queries/user';
import { UNREAD_MESSAGES_NO_READ } from '/client/app/graphql/queries/messages';
import { SET_THEME } from '/client/app/graphql/mutations/sessionSettings';
import { SESSION_SETTINGS } from '/client/app/graphql/queries/sessionSettings';
import { TRANSACTIONS_FOR_USER } from '/client/app/graphql/queries/transactions';
import { logoutSuccess } from '/common/reducers/globalUserStateReducer/globalUserStateReducerActions';
import UserTopBar from './userTopBar';

function UserTopBarContainer(props) {
	async function onLogoutSuccess() {
		await props.logoutSuccess();
		window.location.href = '/';
	}

	const { location } = props;
	const { data } = useQuery(SESSION_USER_WITH_ACCOUNTS, {
		pollInterval: 60 * 1000,
	});

	const { data: sessionSettingsData } = useQuery(SESSION_SETTINGS, { errorPolicy: 'ignore' });
	const { data: unreadMessagesData } = useQuery(UNREAD_MESSAGES_NO_READ, { errorPolicy: 'ignore', pollInterval: 60 * 1000 });

	const [setSessionTheme] = useMutation(SET_THEME);

	async function setSessionThemeFn(theme) {
		if (sessionSettingsData) return setSessionTheme({ variables: { theme } });
		return null;
	}

	useQuery(TRANSACTIONS_FOR_USER, {
		variables: { count: 21, skip: 0 },
		pollInterval: 240 * 1000,
		errorPolicy: 'ignore',
	});

	return (
		<UserTopBar
			loggedInUserEmail={data && data.sessionUser ? data.sessionUser.email : ''}
			accountBalance={data && data.sessionUser ? data.sessionUser.account.balance : 0}
			totalInEscrow={data && data.sessionUser ? data.sessionUser.totalInEscrow : 0}
			onLogoutSuccess={onLogoutSuccess}
			pathname={location.pathname}
			memberOf={data && data.sessionUser ? data.sessionUser.memberOf : []}
			theme={sessionSettingsData && sessionSettingsData.sessionSettings ? sessionSettingsData.sessionSettings.theme : 'dark'}
			setSessionTheme={setSessionThemeFn}
			hasUnreadMessages={
				unreadMessagesData && unreadMessagesData.unreadMessages && unreadMessagesData.unreadMessages.length > 0
			}
			{...props}
		/>
	);
}

UserTopBarContainer.propTypes = {
	logoutSuccess: PropTypes.func.isRequired,
	location: PropTypes.shape({
		pathname: PropTypes.string,
	}).isRequired,
};

const mapDispatchToProps = dispatch => ({
	logoutSuccess: () => dispatch(logoutSuccess()),
});

export default connect(null, mapDispatchToProps)(withRouter(UserTopBarContainer));
