import ReducerConstants from './postActions';

export const defaultState = {
	posts: [],
};

const postReducer = (state, action) => {
	if (state === undefined) {
		return defaultState;
	}
	switch (action.type) {
		case ReducerConstants.UPDATE_POSTS: {
			const { posts } = action;
			return {
				...state,
				posts: [...posts],
			};
		}
		default:
			return state;
	}
};

export default postReducer;
