const ReducerConstants = {
	UPDATE_POSTS: 'UPDATE_POSTS',
	FETCH_POST_ATTEMPT: 'FETCH_POST_ATTEMPT',
	FETCH_POST_SUCCESS: 'FETCH_POST_SUCCESS',
	FETCH_POST_FAILURE: 'FETCH_POST_FAILURE',
	FETCH_POST_SAGA: 'FETCH_POST_SAGA',
};

export const updatePosts = posts => ({
	type: ReducerConstants.UPDATE_POSTS,
	posts,
});
export const fetchPostAttempt = () => ({
	type: ReducerConstants.FETCH_POST_ATTEMPT,
});
export const fetchPostSuccess = () => ({
	type: ReducerConstants.FETCH_POST_SUCCESS,
});
export const fetchPostFailure = error => ({
	type: ReducerConstants.FETCH_POST_FAILURE,
	error,
});
export const fetchPostSaga = (subIDs, count, sort, skip) => ({
	type: ReducerConstants.FETCH_POST_SAGA,
	subIDs,
	count,
	sort,
	skip,
});

export default ReducerConstants;
