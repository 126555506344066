import gql from 'graphql-tag';

export const MESSAGE = gql`
	query message($_id: String!) {
		message(_id: $_id) {
			_id
			from {
				_id
				username
				memberOf {
					_id
					name
					builtin
				}
			}
			to {
				_id
				username
				memberOf {
					_id
					name
					builtin
				}
			}
			subject
			bodyHTML
			read
			date
			deleted
		}
	}
`;

export const UNREAD_MESSAGES = gql`
	query unreadMessages {
		unreadMessages {
			_id
			from {
				_id
				username
			}
			to {
				_id
				username
			}
			subject
			bodyHTML
			read
			date
			deleted
		}
	}
`;

export const UNREAD_MESSAGES_NO_READ = gql`
	query unreadMessages {
		unreadMessages {
			_id
			from {
				_id
				username
			}
			to {
				_id
				username
			}
			subject
			bodyHTML
			date
			deleted
		}
	}
`;

export const MESSAGES = gql`
	query messages($count: Int, $skip: Int, $sortOrder: String) {
		messages(count: $count, skip: $skip, sortOrder: $sortOrder) {
			_id
			from {
				_id
				username
			}
			to {
				_id
				username
			}
			subject
			bodyHTML
			read
			date
			deleted
		}
	}
`;

export const SENT_MESSAGES = gql`
	query messages($count: Int, $skip: Int, $sortOrder: String) {
		sentMessages(count: $count, skip: $skip, sortOrder: $sortOrder) {
			_id
			from {
				_id
				username
			}
			to {
				_id
				username
			}
			subject
			bodyHTML
			read
			date
			deleted
		}
	}
`;
