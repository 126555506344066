import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch, Router } from 'react-router-dom';
import { config as fontAwesomeConfig } from '@fortawesome/fontawesome-svg-core';
import store from '/common/store';
import history from '/common/browserHistory';
import loadable from '@loadable/component';
import ScrollToTop from '/client/app/components/scrollToTop';
import MainLayoutContainer from '../components/mainLayout/mainLayoutContainer';
import LoggedInOnly from '../viewWrappers/loggedInOnly';
import AdminOnly from '../viewWrappers/adminOnly';
import LoggedOutOnly from '../viewWrappers/loggedOutOnly';
import ComponentWithRegex from '../viewWrappers/componentWithRegex';
import NoSuchRouteView from './noSuchRoute/noSuchRoute';

import './main.scss';

fontAwesomeConfig.autoAddCss = false;

const EmailConfirmationSuccess = loadable(() => import('./emailConfirmationSuccess'));
const EmailConfirmationFailure = loadable(() => import('./emailConfirmationFailure'));
const PostContainer = loadable(() => import('./post/postContainer'));
const SubContainer = loadable(() => import('./sub/subContainer'));
const UserView = loadable(() => import('./user/userContainer'));
const CreateAccountView = loadable(() => import('./createAccount/createAccount'));
const ResetPasswordView = loadable(() => import('./resetPassword/resetPassword'));
const SignInView = loadable(() => import('./signIn/signIn'));
const UserHomeView = loadable(() => import('/client/app/views/userHome/userHome'));
const UserSettingsView = loadable(() => import('./userSettings/userSettingsContainer'));
const AboutView = loadable(() => import('./about'));
const PrivacyPolicyView = loadable(() => import('./privacyPolicy/privacyPolicy'));
const CreateCommunityView = loadable(() => import('./createCommunity/createCommunityContainer'));
const BalanceView = loadable(() => import('./balance/balance'));
const AddFundsView = loadable(() => import('./addFunds/addFundsContainer'));
const WithdrawFundsView = loadable(() => import('./withdrawFunds/withdrawFunds'));
const MessagesView = loadable(() => import('./messages/messagesContainer'));
const CreatePostView = loadable(() => import('./createPost/createPostContainer'));
const AdminView = loadable(() => import('./admin/adminView'));
const SendMessageView = loadable(() => import('/client/app/views/sendMessage/sendMessage'));
const ModerationView = loadable(() => import('/client/app/views/moderation/moderation'));
const MainFeedView = loadable(() => import('/client/app/components/mainFeed'));

export const mainLayout = (
	<MainLayoutContainer>
		<Switch>
			<Route path="/emailConfirmationSuccess" component={EmailConfirmationSuccess} />
			<Route path="/emailConfirmationFailure" component={EmailConfirmationFailure} />
			<Route
				path="/s/:sub([A-Za-z_0-9]+)/comments/:postId([A-Za-z_0-9]+)/:commentId([A-Za-z_0-9]+)"
				component={ComponentWithRegex(PostContainer)}
			/>
			<Route path="/s/:sub([A-Za-z_0-9]+)/comments/:postId([A-Za-z_0-9]+)" component={ComponentWithRegex(PostContainer)} />
			<Route exact path="/s/:sub([A-Za-z_0-9+]+)" component={ComponentWithRegex(SubContainer)} />
			<Route exact path="/u/:user([A-Za-z_0-9]+)" component={ComponentWithRegex(UserView)} />
			<Route path="/createAccount" component={LoggedOutOnly(CreateAccountView)} />
			<Route path="/resetPassword" component={LoggedOutOnly(ResetPasswordView)} />
			<Route path="/signin" component={LoggedOutOnly(SignInView)} />
			<Route path="/subscribed" component={LoggedInOnly(UserHomeView)} />
			<Route path="/main" component={LoggedOutOnly(MainFeedView)} />
			<Route path="/userSettings" component={LoggedInOnly(UserSettingsView)} />
			<Route path="/about" component={AboutView} />
			<Route path="/privacyPolicy" component={PrivacyPolicyView} />
			<Route path="/createCommunity" component={LoggedInOnly(CreateCommunityView)} />
			<Route path="/balance" component={LoggedInOnly(BalanceView)} />
			<Route path="/addFunds" component={LoggedInOnly(AddFundsView)} />
			<Route path="/withdrawFunds" component={LoggedInOnly(WithdrawFundsView)} />
			<Route path="/messages" component={LoggedInOnly(MessagesView)} />
			<Route path="/createPost" component={LoggedInOnly(CreatePostView)} />
			<Route path="/admin" component={LoggedInOnly(AdminView)} />
			<Route path="/sendMessage" component={LoggedInOnly(AdminOnly(SendMessageView))} />
			<Route path="/moderation" component={LoggedInOnly(ModerationView)} />
			<Route exact path="/" component={LoggedOutOnly(SignInView)} />
			<Route component={NoSuchRouteView} />
		</Switch>
	</MainLayoutContainer>
);

export default function IndexView() {
	return (
		<Provider store={store}>
			<Router history={history}>
				<ScrollToTop />
				{mainLayout}
			</Router>
		</Provider>
	);
}
