/* eslint default-case: 0 */

import ReducerConstants from './createAccountFormReducerActions';

const inputsAreValid = function inputsAreValidFn(action) {
	let result = true;

	// Username is required
	if (action.username.trim().length === 0) {
		result = false;
	}
	// Email is required
	if (action.email.trim().length === 0) {
		result = false;
	}
	// Email confirmation is required
	if (action.emailConfirmation.trim().length === 0) {
		result = false;
	}
	// Email confirmation must match email
	if (action.email.trim() !== action.emailConfirmation.trim()) {
		result = false;
	}
	// Password is required
	if (action.password.length === 0) {
		result = false;
	}
	// Password confirmation is required
	if (action.passwordConfirmation.length === 0) {
		result = false;
	}
	// Password confirmation must match password
	if (action.password !== action.passwordConfirmation) {
		result = false;
	}

	return result;
};

export default function createAccountFormReducer(state, action) {
	if (state === undefined) {
		// eslint-disable-next-line no-param-reassign
		state = {
			email: '',
			emailError: false,
			username: '',
			usernameError: false,
			emailConfirmation: '',
			emailConfirmationError: false,
			password: '',
			passwordError: false,
			passwordConfirmation: '',
			passwordConfirmationError: false,
			submitDisabled: true,
			submitButtonText: 'create account',
			error: '',
		};
		return state;
	}
	// Signifies a change in form values
	if (action.type === ReducerConstants.CREATE_ACCOUNT_FORM_VALUES_UPDATED) {
		const validInputs = inputsAreValid(action);
		return {
			...state,
			submitDisabled: !validInputs,
			username: action.username,
			email: action.email,
			emailError: action.emailError,
			emailConfirmation: action.emailConfirmation,
			emailConfirmationError: action.emailConfirmationError === true,
			password: action.password,
			passwordError: action.passwordError,
			passwordConfirmation: action.passwordConfirmation,
			passwordConfirmationError: action.passwordConfirmationError,
			error: action.error,
		};
	}
	// Signifies the beginning of a login attempt
	if (action.type === ReducerConstants.ACCOUNT_CREATION_ATTEMPT) {
		return { ...state, submitDisabled: true, submitButtonText: 'processing...' };
	}

	// Signifies login success and provides the username of the logged in user
	if (action.type === ReducerConstants.ACCOUNT_CREATION_SUCCESS) {
		return { ...state, submitDisabled: true, error: '', submitButtonText: 'welcome!' };
	}

	// Signifies login failure and optionally provides one or more errors
	if (action.type === ReducerConstants.ACCOUNT_CREATION_FAILURE) {
		let err = '';
		if (action.error.length > 0) {
			err = action.error;
		}
		return { ...state, submitDisabled: false, submitButtonText: 'create account', error: err };
	}
	return state;
}
