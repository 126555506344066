import loginFormReducer from './reducers/loginFormReducer/loginFormReducer';
import globalUserStateReducer from './reducers/globalUserStateReducer/globalUserStateReducer';
import createAccountFormReducer from './reducers/createAccountFormReducer/createAccountFormReducer';
import inputValueReducer from './reducers/common/input/inputValueReducer/inputValueReducer';
import communityReducer from './reducers/communityReducer/communityReducer';
import postReducer from './reducers/postReducer/postReducer';
import createPostFormReducer from './reducers/createPostFormReducer/createPostFormReducer';
import communitySearchReducer from './reducers/communitySearchReducer/communitySearchReducer';
import fileReducer from './reducers/fileReducer/fileReducer';

const { combineReducers } = require('redux');

export default combineReducers({
	loginFormState: loginFormReducer,
	globalUserState: globalUserStateReducer,
	createAccountFormState: createAccountFormReducer,
	inputValueState: inputValueReducer,
	communityState: communityReducer,
	createPostFormState: createPostFormReducer,
	communitySearchState: communitySearchReducer,
	postState: postReducer,
	fileState: fileReducer,
});
