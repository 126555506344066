const ReducerConstants = {
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
	LOGIN_FAILURE: 'LOGIN_FAILURE',
	UPDATE_SUBSCRIBED_COMMUNITIES: 'UPDATE_SUBSCRIBED_COMMUNITIES',
	UPDATE_USER_ACCOUNT_BALANCE: 'UPDATE_USER_ACCOUNT_BALANCE',
};

export const loginSuccess = (email, username) => ({
	type: ReducerConstants.LOGIN_SUCCESS,
	email,
	username,
});

export const loginFailure = error => ({
	type: ReducerConstants.LOGIN_FAILURE,
	error,
});

export const logoutSuccess = () => ({
	type: ReducerConstants.LOGOUT_SUCCESS,
});

export const updateSubscribedCommunities = subscribedCommunityNames => ({
	type: ReducerConstants.UPDATE_SUBSCRIBED_COMMUNITIES,
	subscribedCommunityNames,
});

export const updateUserAccountBalance = accountBalance => ({
	type: ReducerConstants.UPDATE_USER_ACCOUNT_BALANCE,
	accountBalance,
});

export default ReducerConstants;
