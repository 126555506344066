const ReducerConstants = {
	INPUT_VALUE_UPDATED: 'INPUT_VALUE_UPDATED',
};

export const updateValue = (uid, value) => {
	if (!uid) throw new Error('uid is required');

	return {
		type: ReducerConstants.INPUT_VALUE_UPDATED,
		uid,
		value,
	};
};

export default ReducerConstants;
