import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

/**
 * Higher-order component (HOC) to wrap restricted pages
 */
export default function LoggedInOnly(BaseComponent) {
	class Restricted extends Component {
		constructor(props) {
			super(props);
			this.state = {};
		}

		static getDerivedStateFromProps(nextProps) {
			const { history, location } = nextProps;
			if (!nextProps.isLoggedIn) {
				history.replace({ pathname: '/signin', search: `dest=${encodeURIComponent(location.pathname)}` });
			}
			return null;
		}

		render() {
			const { location, staticContext, isLoggedIn } = this.props;

			if (isLoggedIn) return <BaseComponent {...this.props} />;

			const destinationURL = `/signin?dest=${encodeURIComponent(location.pathname)}`;
			if (staticContext) staticContext.url = destinationURL;

			return <Redirect to={destinationURL} />;
		}
	}

	Restricted.propTypes = {
		location: PropTypes.shape({
			pathname: PropTypes.string,
		}).isRequired,
		isLoggedIn: PropTypes.bool.isRequired,
		// eslint-disable-next-line react/forbid-prop-types
		staticContext: PropTypes.object,
		history: PropTypes.shape({
			replace: PropTypes.func,
		}).isRequired,
	};

	Restricted.defaultProps = {
		staticContext: null,
	};

	const mapStateToProps = state => ({
		isLoggedIn: !!state.globalUserState.loggedInUserEmail,
	});

	return withRouter(connect(mapStateToProps)(Restricted));
}
