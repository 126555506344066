import ReactGA from 'react-ga';

const createHistory = require('history').createBrowserHistory;

function doCreateHistory() {
	if (typeof document !== 'undefined') {
		ReactGA.initialize('UA-155382093-1');
		return createHistory();
	}
	return {};
}

const history = doCreateHistory();

export default history;
