import React from 'react';
import PropTypes from 'prop-types';

/**
 * Higher-order component (HOC) to take route match parameters and pass them to the wrapped component as 'urlParameters'
 */
export default function ComponentWithRegex(BaseComponent) {
	const WithRegex = ({ match }) => <BaseComponent urlParameters={match.params} />;

	WithRegex.propTypes = {
		match: PropTypes.shape({
			params: PropTypes.object,
		}).isRequired,
	};

	return WithRegex;
}
