import { InMemoryCache } from 'apollo-cache-inmemory';
import { toIdValue } from 'apollo-utilities';

export default function createCache() {
	const cache: any = new InMemoryCache({
		cacheRedirects: {
			Query: {
				comment: (_, args) => {
					return toIdValue(cache.config.dataIdFromObject({ __typename: 'Comment', id: args._id }));
				},
				post: (_, args) => {
					return toIdValue(cache.config.dataIdFromObject({ __typename: 'Post', id: args._id }));
				},
				sub: (_, args) => {
					return toIdValue(cache.config.dataIdFromObject({ __typename: 'Sub', id: args._id }));
				},
				user: (_, args) => {
					return toIdValue(cache.config.dataIdFromObject({ __typename: 'User', id: args._id }));
				},
				message: (_, args) => {
					return toIdValue(cache.config.dataIdFromObject({ __typename: 'Message', id: args._id }));
				},
			},
		},
	});
	return cache;
}
