import ReducerConstants from './createPostFormActions';

export const inputsAreValid = action => {
	let result = true;

	if (action.title.trim().length === 0) result = false;

	if (action.postType === 'link' && action.url.trim().length === 0) return false;

	if (action.postType === 'media' && action.media.length === 0) return false;

	return result;
};

export const defaultState = {
	title: '',
	community: '',
	titleError: false,
	body: '',
	bodyHTML: '',
	bodyError: false,
	url: '',
	urlError: false,
	media: [],
	mediaError: false,
	submitDisabled: true,
	hasSubmitted: false,
	draftSaved: false,
	isSavable: false,
	saveDraftError: '',
	error: '',
	mostRecentCommunity: '',
	postType: 'link',
};

const createPostFormReducer = (state, action) => {
	if (state === undefined) {
		// eslint-disable-next-line no-param-reassign
		state = defaultState;
		return state;
	}
	switch (action.type) {
		case ReducerConstants.CREATE_POST_FORM_VALUES_UPDATED: {
			const hasValidInput = inputsAreValid(action);
			return {
				...state,
				submitDisabled: !hasValidInput,
				hasSubmitted: false,
				isSavable: action.isSavable,
				saveDraftError: action.saveDraftError,
				title: action.title,
				titleError: action.titleError,
				body: action.body,
				bodyHTML: action.bodyHTML,
				bodyError: action.bodyError,
				url: action.url,
				urlError: action.urlError,
				error: action.error,
				postType: action.postType,
				media: action.media,
				mediaError: action.mediaError,
				community: action.community,
			};
		}
		case ReducerConstants.CREATE_POST_ATTEMPT: {
			return {
				...state,
				submitDisabled: true,
				isSavable: false,
			};
		}
		case ReducerConstants.CREATE_POST_SUCCESS: {
			return {
				...defaultState,
				hasSubmitted: true,
			};
		}
		case ReducerConstants.CREATE_POST_FAILURE: {
			return {
				...state,
				submitDisabled: false,
				isSavable: true,
				error: action.error,
			};
		}
		case '@@router/LOCATION_CHANGE': {
			const path = action.payload.pathname;
			if (path.match(/\/s\//)) {
				return {
					...state,
					mostRecentCommunity: path.slice(3, path.length),
				};
			}
			return state;
		}
		case ReducerConstants.CREATE_POST_DISABLE_SUBMIT: {
			return {
				...state,
				submitDisabled: true,
			};
		}
		case ReducerConstants.CREATE_POST_ENABLE_SUBMIT: {
			return {
				...state,
				submitDisabled: false,
			};
		}
		case ReducerConstants.CREATE_POST_ENABLE_SAVE_DRAFT: {
			return {
				...state,
				isSavable: true,
			};
		}
		case ReducerConstants.CREATE_POST_DISABLE_SAVE_DRAFT: {
			return {
				...state,
				isSavable: false,
			};
		}
		default:
			return state;
	}
};

export const getPostFormState = state => {
	const community = state.communitySearchState.value;
	const communityError = state.communitySearchState.error;
	return {
		...state.createPostFormState,
		community,
		communityError,
	};
};

export const getActionCost = state => {
	const selectedCommunity = state.communitySearchState.value;
	try {
		const { actionCost } = state.communityState.communities.filter(
			community => community.name.toLowerCase() === selectedCommunity.toLowerCase()
		)[0];
		return actionCost;
	} catch (error) {
		return 0.0;
	}
};

export default createPostFormReducer;
