const ReducerConstants = {
	FILE_UPLOAD_REQUEST: 'FILE_UPLOAD_REQUEST',
	FILE_UPLOAD_CANCEL: 'FILE_UPLOAD_CANCEL',
	FILE_UPLOAD_CANCELLED: 'FILE_UPLOAD_CANCELLED',
	FILE_UPLOAD_START: 'FILE_UPLOAD_START',
	FILE_UPLOAD_SUCCESS: 'FILE_UPLOAD_SUCCESS',
	FILE_UPLOAD_ERROR: 'FILE_UPLOAD_ERROR',
	FILE_UPLOAD_COMPLETE: 'FILE_UPLOAD_COMPLETE',
	FILE_UPLOAD_PROGRESS: 'FILE_UPLOAD_PROGRESS',
	FILE_LIST_UPDATE: 'FILE_LIST_UPDATE',
};
export default ReducerConstants;

export const updateFiles = (fileList, uid) => ({
	type: ReducerConstants.FILE_LIST_UPDATE,
	fileList,
	uid,
});

export const fileUploadRequest = (fileEntry, uid) => ({
	type: ReducerConstants.FILE_UPLOAD_REQUEST,
	fileEntry,
	uid,
});

export const fileUploadCancel = (name, uid) => ({
	type: ReducerConstants.FILE_UPLOAD_CANCEL,
	name,
	uid,
});

export const fileUploadCancelled = (name, uid) => ({
	type: ReducerConstants.FILE_UPLOAD_CANCELLED,
	name,
	uid,
});

export const fileUploadStart = (name, uid) => ({
	type: ReducerConstants.FILE_UPLOAD_START,
	name,
	uid,
});

export const fileUploadSuccess = (name, originalId, thumbnailId, uid) => ({
	type: ReducerConstants.FILE_UPLOAD_SUCCESS,
	name,
	originalId,
	thumbnailId,
	uid,
});

export const fileUploadError = (name, error, uid) => ({
	type: ReducerConstants.FILE_UPLOAD_ERROR,
	name,
	error,
	uid,
});

export const fileUploadComplete = (name, uid) => ({
	type: ReducerConstants.FILE_UPLOAD_COMPLETE,
	name,
	uid,
});

export const fileUploadProgress = (fileEntry, progress, uid) => ({
	type: ReducerConstants.FILE_UPLOAD_PROGRESS,
	fileEntry,
	progress,
	uid,
});
