import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const SESSION_SETTINGS = gql`
	query sessionSettings {
		sessionSettings {
			_id
			theme
			feedViewMode
		}
	}
`;
