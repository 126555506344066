const ReducerConstants = {
	CREATE_POST_FORM_VALUES_UPDATED: 'CREATE_POST_FORM_VALUES_UPDATED',
	CREATE_POST_SAVE_DRAFT: 'CREATE_POST_SAVE_DRAFT',
	CREATE_POST_SAVE_DRAFT_SUCCESS: 'CREATE_POST_SAVE_DRAFT_SUCCESS',
	CREATE_POST_SAVE_DRAFT_FAILURE: 'CREATE_POST_SAVE_DRAFT_FAILURE',
	CREATE_POST_ATTEMPT: 'CREATE_POST_ATTEMPT',
	CREATE_POST_SUCCESS: 'CREATE_POST_SUCCESS',
	CREATE_POST_FAILURE: 'CREATE_POST_FAILURE',
	CREATE_POST_DISABLE_SUBMIT: 'CREATE_POST_DISABLE_SUBMIT',
	CREATE_POST_ENABLE_SUBMIT: 'CREATE_POST_ENABLE_SUBMIT',
	CREATE_POST_DISABLE_SAVE_DRAFT: 'CREATE_POST_DISABLE_SAVE_DRAFT',
	CREATE_POST_ENABLE_SAVE_DRAFT: 'CREATE_POST_ENABLE_SAVE_DRAFT',
};

export const createPostFormValuesUpdated = formData => ({
	type: ReducerConstants.CREATE_POST_FORM_VALUES_UPDATED,
	...formData,
});

export const createPostEnableSaveDraft = () => ({
	type: ReducerConstants.CREATE_POST_ENABLE_SAVE_DRAFT,
});

export const createPostDisableSaveDraft = () => ({
	type: ReducerConstants.CREATE_POST_DISABLE_SAVE_DRAFT,
});
export const createPostSaveDraft = formData => ({
	type: ReducerConstants.CREATE_POST_SAVE_DRAFT,
	formData,
});

export const createPostSaveDraftSuccess = () => ({
	type: ReducerConstants.CREATE_POST_SAVE_DRAFT_SUCCESS,
});

export const createPostSaveDraftFailure = error => ({
	type: ReducerConstants.CREATE_POST_SAVE_DRAFT_FAILURE,
	error,
});

export const createPostAttempt = () => ({
	type: ReducerConstants.CREATE_POST_ATTEMPT,
});

export const createPostSuccess = () => ({
	type: ReducerConstants.CREATE_POST_SUCCESS,
});

export const createPostFailure = error => ({
	type: ReducerConstants.CREATE_POST_FAILURE,
	error,
});

export const createPostDisableSubmit = () => ({
	type: ReducerConstants.CREATE_POST_DISABLE_SUBMIT,
});

export const createPostEnableSubmit = () => ({
	type: ReducerConstants.CREATE_POST_ENABLE_SUBMIT,
});

export default ReducerConstants;
