import gql from 'graphql-tag';

export const SESSION_USER_WITH_ACCOUNTS = gql`
	query sessionUser {
		sessionUser {
			_id
			username
			email
			account {
				_id
				balance
			}
			escrowAccount {
				_id
				balance
			}
			totalInEscrow
			memberOf {
				_id
				name
				builtin
			}
			subscriptions {
				users {
					_id
					username
					usernameLowercase
				}
				subs {
					_id
					name
				}
			}
		}
	}
`;

export const USER_BY_USERNAME = gql`
	query userByUsername($username: String!) {
		userByUsername(username: $username) {
			_id
			username
			subscribers
			memberSince
			moderatorReputation
			memberOf {
				_id
				name
				builtin
			}
		}
	}
`;
