const ReducerConstants = {
	CREATE_ACCOUNT_FORM_VALUES_UPDATED: 'CREATE_ACCOUNT_FORM_VALUES_UPDATED',
	ACCOUNT_CREATION_ATTEMPT: 'ACCOUNT_CREATION_ATTEMPT',
	ACCOUNT_CREATION_SUCCESS: 'ACCOUNT_CREATION_SUCCESS',
	ACCOUNT_CREATION_FAILURE: 'ACCOUNT_CREATION_FAILURE',
};

export const createAccountFormValuesUpdated = (
	username,
	email,
	emailError,
	emailConfirmation,
	emailConfirmationError,
	password,
	passwordError,
	passwordConfirmation,
	passwordConfirmationError,
	error
) => ({
	type: ReducerConstants.CREATE_ACCOUNT_FORM_VALUES_UPDATED,
	username,
	email,
	emailError,
	emailConfirmation,
	emailConfirmationError,
	password,
	passwordError,
	passwordConfirmation,
	passwordConfirmationError,
	error,
});

export const attemptAccountCreation = () => ({
	type: ReducerConstants.ACCOUNT_CREATION_ATTEMPT,
});

export const accountCreationSuccess = () => ({
	type: ReducerConstants.ACCOUNT_CREATION_SUCCESS,
});

export const accountCreationFailure = error => ({
	type: ReducerConstants.ACCOUNT_CREATION_FAILURE,
	error,
});

export default ReducerConstants;
