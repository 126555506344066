/* eslint default-case: 0 */

import ReducerConstants from './loginFormReducerActions';

export default function loginFomReducer(state, action) {
	if (state === undefined) {
		// eslint-disable-next-line no-param-reassign
		state = {
			email: '',
			password: '',
			error: '',
			submitDisabled: true,
			submitButtonText: 'sign in',
			resetDisabled: true,
			resetPasswordButtonText: 'reset password',
		};
		return state;
	}

	// Signifies the beginning of a login attempt
	if (action.type === ReducerConstants.LOGIN_ATTEMPT) {
		return { ...state, submitDisabled: true, submitButtonText: 'authenticating' };
	}

	// Signifies login success and provides the username of the logged in user
	if (action.type === ReducerConstants.LOGIN_SUCCESS) {
		return {
			...state,
			submitDisabled: true,
			error: '',
			submitButtonText: 'welcome!',
		};
	}

	// Signifies that the user has logged out, which should reset the form
	if (action.type === ReducerConstants.LOGOUT_SUCCESS) {
		return {
			...state,
			submitDisabled: true,
			email: '',
			password: '',
			error: '',
			submitButtonText: 'sign in',
		};
	}

	// Signifies login failure and optionally provides one or more errors
	if (action.type === ReducerConstants.LOGIN_FAILURE) {
		let err = '';
		if (action.error.length > 0) {
			err = action.error;
		}
		return {
			...state,
			submitDisabled: false,
			submitButtonText: 'sign in',
			error: err,
		};
	}
	// Signifies a change in form values
	if (action.type === ReducerConstants.LOGIN_FORM_VALUES_UPDATED) {
		return {
			...state,
			submitDisabled: !(action.email.length && action.password.length),
			email: action.email,
			password: action.password,
		};
	}
	return state;
}
