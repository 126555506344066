import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './mainLayout.scss';
import UserTopBarContainer from '../userTopBar/userTopBarContainer';
import Hideable from '/client/app/components/common/hideable';

export default function MainLayout(props) {
	const [renderedClientSide, setRenderedClientSide] = useState(false);

	useEffect(() => {
		document.title = 'pfyt';

		// For testing - provides an indicator that the first client-side render has completed
		setRenderedClientSide(true);
	});

	function getLinks() {
		const { loggedInUserEmail } = props;
		return [loggedInUserEmail ? getloggedInUserEmailLinks() : getLoggedOutUserLinks(), getEverPresentLinks()];
	}

	function getloggedInUserEmailLinks() {
		return (
			<span key="loggedInUserEmailFooterLinks">
				<Link id="mainFeedFooterLink" title="subscribed" to="/subscribed">
					subscribed
				</Link>{' '}
				·{' '}
				<Link id="balanceFooterLink" title="balance" to="/balance">
					balance
				</Link>{' '}
				·{' '}
				<Link id="messagesFooterLink" title="messages" to="/messages">
					messages
				</Link>{' '}
				·{' '}
				<Link id="moderationFooterLink" title="moderation" to="/moderation">
					moderation
				</Link>{' '}
				·{' '}
				<Link id="userSettingsFooterLink" title="user settings" to="/userSettings">
					user settings
				</Link>{' '}
				·{' '}
				<Link id="createPostFooterLink" title="create post" to="/createPost">
					create post
				</Link>{' '}
				·{' '}
				<Link id="createCommunityFooterLink" title="create community" to="/createCommunity">
					create community
				</Link>{' '}
				·{' '}
			</span>
		);
	}

	function getLoggedOutUserLinks() {
		return (
			<span key="loggedOutUserFooterLinks">
				<Link id="signinFooterLink" to="/signin">
					sign in
				</Link>{' '}
				·{' '}
				<Link id="resetPasswordFooterLink" to="/resetPassword">
					reset password
				</Link>{' '}
				·{' '}
			</span>
		);
	}

	function getEverPresentLinks() {
		return (
			<span key="evetPresentFooterLinks">
				<Link id="aboutFooterLink" to="/about">
					about
				</Link>{' '}
				·{' '}
				<Link id="privacyFooterLink" target="_blank" to="/privacyPolicy">
					privacy
				</Link>
			</span>
		);
	}

	const { children } = props;

	return (
		<div>
			<div className="dynamicallyPaddedContainer">
				<UserTopBarContainer />
				<main className="mainContent" id="main">
					{children}
				</main>
				<footer className="innerFooter">
					<div className="footerDivider" />
					{getLinks()}
					<br />
					copyright © {new Date().getFullYear()}{' '}
					<a href="https://www.wildcodemonkey.com" rel="noopener noreferrer" target="_blank">
						wild code monkey llc
					</a>{' '}
					&mdash; all rights reserved
				</footer>
			</div>
			<Hideable hidden={!renderedClientSide}>
				<span id="cri" hidden />
			</Hideable>
		</div>
	);
}

MainLayout.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
	loggedInUserEmail: PropTypes.string,
};

MainLayout.defaultProps = {
	children: [],
	loggedInUserEmail: null,
};
