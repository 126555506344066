const ReducerConstants = {
	COMMUNITY_SEARCH_VALUE_UPDATE: 'COMMUNITY_SEARCH_VALUE_UPDATE',
	COMMUNITY_SEARCH_ERROR_SET: 'COMMUNITY_SEARCH_ERROR_SET',
};

export const updateValue = value => ({
	type: ReducerConstants.COMMUNITY_SEARCH_VALUE_UPDATE,
	value,
});
export const setError = error => ({
	type: ReducerConstants.COMMUNITY_SEARCH_ERROR_SET,
	error,
});

export default ReducerConstants;
