import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import mainSaga from './sagas';

const { applyMiddleware } = require('redux');
const { createStore } = require('redux');
const storage = require('redux-storage');

const reducer = storage.reducer(reducers);

const rootReducer = (state, action) => {
	if (action.type === 'LOGOUT_SUCCESS') return reducer(undefined, action);
	return reducer(state, action);
};

const createEngine = function createEngine(key, replacer, reviver) {
	return {
		async load() {
			if (localStorage) return JSON.parse(localStorage.getItem(key), reviver) || {};
			return {};
		},

		async save(state) {
			if (localStorage) {
				// Make a copy and remove router, otherwise we'll always restore the last visited URL
				const stateCopy = { treeViewState: state.treeViewState, nWayToggleState: state.nWayToggleState };
				const jsonState = JSON.stringify(stateCopy, replacer);
				localStorage.setItem(key, jsonState);
			}
		},
	};
};

const preloadedState = typeof window !== 'undefined' ? window.PRELOADED_PFYT_STATE : {};

const engine = createEngine('simianstack.redux', null, null);

const sagaMiddleware = createSagaMiddleware();

const middlewares = [storage.createMiddleware(engine), sagaMiddleware];

if (process.env.NODE_ENV === `development`) {
	const logger = createLogger({
		diff: true,
		predicate: (getState, action) => action.type !== 'REDUX_STORAGE_SAVE',
	});
	middlewares.push(logger);
}
const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);

const store = createStoreWithMiddleware(rootReducer, preloadedState);

sagaMiddleware.run(mainSaga);

if (typeof window !== 'undefined') {
	const load = storage.createLoader(engine);
	load(store);
}

export default store;
