import ReducerConstants from './communityReducerActions';

export default function communityStateReducer(state, action) {
	if (state === undefined) {
		return { communityNames: null };
	}

	if (action.type === ReducerConstants.UPDATE_COMMUNITIES) {
		const communityNames = action.communities.map(community => community.name);
		return {
			...state,
			communities: action.communities,
			communityNames,
		};
	}
	return state;
}
