import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
	faSun,
	faMoon,
	faEye,
	faEnvelope,
	faLock,
	faCog,
	faSignInAlt,
	faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faEnvelope as farEnvelope } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logout } from '/client/app/api/backend';
import Hideable from '../common/hideable';

require('./userTopBar.scss');

const balanceFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 2,
});

const DARK_THEME_CSS = '/public/dark.styles.css';
const LIGHT_THEME_CSS = '/public/light.styles.css';

export default function UserTopBar(props) {
	const {
		loggedInUserEmail,
		totalInEscrow,
		accountBalance,
		theme,
		setSessionTheme,
		hasUnreadMessages,
		onLogoutSuccess,
	} = props;

	const [style, setStyle] = useState(theme === 'dark' ? DARK_THEME_CSS : LIGHT_THEME_CSS);

	useEffect(() => {
		const styleRef = document.getElementById('style-theme');
		styleRef.href = style;
	}, [style]);

	function signOut() {
		logout()
			.then(success => {
				if (success) onLogoutSuccess();
				else console.log('Unspecified error signing out.');
			})
			.catch(error => {
				console.log(`Error signing out: ${error.message}`);
			});
	}

	function renderBalance(balance) {
		const formattedEffectiveBalance = balanceFormatter.format(Math.min(1000000, balance / 100));
		if (balance / 100 > 1000000) return <>{formattedEffectiveBalance}+</>;
		return formattedEffectiveBalance;
	}

	function isAdmin() {
		const { memberOf } = props;
		return !!memberOf.find(group => group.name === 'Site Admins' && group.builtin);
	}

	function onThemeSelect(option) {
		if (option === 0) {
			if (style !== LIGHT_THEME_CSS) {
				setSessionTheme('light');
				setStyle(LIGHT_THEME_CSS);
			}
		} else if (option === 1) {
			if (style !== DARK_THEME_CSS) {
				setSessionTheme('dark');
				setStyle(DARK_THEME_CSS);
			}
		}
	}

	function getThemeToggle() {
		const themeConstant = style === '/public/dark.styles.css' ? 1 : 0;
		const themeIcon = themeConstant === 1 ? faSun : faMoon;
		return (
			<button
				type="button"
				title={`use ${themeConstant === 1 ? 'light' : 'dark'} theme on this device`}
				className="themeToggleButton"
				name="toggle theme"
				onClick={() => onThemeSelect(themeConstant === 1 ? 0 : 1)}
			>
				<FontAwesomeIcon icon={themeIcon} />
			</button>
		);
	}

	return (
		<nav className="userTopBar">
			<div className="userTopBarHeader">
				<Link title="subscribed" className="userTopBarBrandLink" to={{ pathname: '/subscribed' }}>
					<img className="userTopBarBrand" alt="Brand" src="/static/img/brand-temp.svg" />
				</Link>
				<span className="userTopBarRight">
					{loggedInUserEmail ? (
						<>
							<Link
								className="topBarAccountBalance"
								title="available balance"
								name="balance icon"
								to={{ pathname: '/balance' }}
							>
								<span className="userTopBalance">{renderBalance(accountBalance)}</span>
							</Link>
							<Hideable hidden={totalInEscrow === 0}>
								<span className="userTopEscrowBalanceContainer">
									<Link title="vesting balance" name="vesting balance" to={{ pathname: '/balance' }}>
										<span className="userTopEscrowBalance">{renderBalance(totalInEscrow)}</span>
									</Link>
								</span>
							</Hideable>
							&nbsp;
							{getThemeToggle()}
							<Link title="moderation" name="moderation icon" to={{ pathname: '/moderation' }}>
								<FontAwesomeIcon icon={faEye} />
							</Link>
							&nbsp;
							<Link title="messages" name="messages icon" to={{ pathname: '/messages' }}>
								<FontAwesomeIcon
									icon={hasUnreadMessages ? faEnvelope : farEnvelope}
									className={hasUnreadMessages ? 'hasUnreadMessages' : ''}
								/>
							</Link>
							&nbsp;
							<Hideable hidden={!isAdmin()}>
								<Link title="admin" name="admin control panel icon" to={{ pathname: '/admin' }}>
									<FontAwesomeIcon icon={faLock} />
								</Link>
								&nbsp;
							</Hideable>
							<Link title="user settings" name="user settings icon" to={{ pathname: '/userSettings' }}>
								<FontAwesomeIcon icon={faCog} />
							</Link>
							&nbsp;
						</>
					) : (
						getThemeToggle()
					)}
					<a
						href={loggedInUserEmail ? '#' : '/signin'}
						target="_self"
						id="signInSignOut"
						title={loggedInUserEmail ? 'sign out' : 'sign in'}
						onClick={loggedInUserEmail ? signOut : null}
					>
						<FontAwesomeIcon icon={loggedInUserEmail ? faSignOutAlt : faSignInAlt} aria-hidden="true" />
					</a>
				</span>
			</div>
		</nav>
	);
}

UserTopBar.propTypes = {
	loggedInUserEmail: PropTypes.string,
	theme: PropTypes.string.isRequired,
	setSessionTheme: PropTypes.func.isRequired,
	onLogoutSuccess: PropTypes.func.isRequired,
	accountBalance: PropTypes.number,
	totalInEscrow: PropTypes.number,
	memberOf: PropTypes.arrayOf(
		PropTypes.shape({
			_id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			builtin: PropTypes.bool.isRequired,
		})
	).isRequired,
	hasUnreadMessages: PropTypes.bool,
};

UserTopBar.defaultProps = {
	loggedInUserEmail: null,
	accountBalance: 0,
	totalInEscrow: 0,
	hasUnreadMessages: false,
};
