import ReducerConstants from './globalUserStateReducerActions';

export default function globalUserStateReducer(state, action) {
	if (state === undefined) {
		return { loggedInUserEmail: null, loggedInUserUsername: null, subscribedCommunityNames: [], accountBalance: 0 };
	}

	if (action.type === ReducerConstants.LOGIN_SUCCESS)
		return {
			...state,
			loggedInUserEmail: action.email,
			loggedInUserUsername: action.username,
		};
	if (action.type === ReducerConstants.LOGOUT_SUCCESS)
		return {
			...state,
			loggedInUserEmail: null,
			loggedInUserUsername: null,
		};
	if (action.type === ReducerConstants.UPDATE_SUBSCRIBED_COMMUNITIES)
		return {
			...state,
			subscribedCommunityNames: action.subscribedCommunityNames,
		};
	if (action.type === ReducerConstants.UPDATE_USER_ACCOUNT_BALANCE)
		return {
			...state,
			accountBalance: action.accountBalance,
		};

	return state;
}
