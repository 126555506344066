/* eslint default-case: 0 */

import ReducerConstants from './inputValueReducerActions';

export default function inputValueReducer(state, action) {
	if (state === undefined) {
		// eslint-disable-next-line no-param-reassign
		state = { fieldValuesByUID: {} };
		return state;
	}

	if (action.type === ReducerConstants.INPUT_VALUE_UPDATED) {
		const newFieldValuesByUID = { ...state.fieldValuesByUID };
		newFieldValuesByUID[action.uid] = action.value;
		return {
			...state,
			fieldValuesByUID: newFieldValuesByUID,
		};
	}

	return state;
}
