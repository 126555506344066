import 'core-js/stable';
import { loadableReady } from '@loadable/component';
import 'regenerator-runtime/runtime';
import { hydrate } from 'react-dom';
import IndexView from '/client/app/views/index';
import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import createCache from '/common/graphql/createCache';

const uri = window.__API_URI__ || 'http://localhost:3000/graphql';

const client = new ApolloClient({
	link: ApolloLink.from([
		onError(({ graphQLErrors, networkError }) => {
			if (graphQLErrors)
				graphQLErrors.forEach(({ message, locations, path }) =>
					console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
				);
			if (networkError) console.log(`[Network error]: ${networkError}`);
		}),
		new HttpLink({
			uri,
			credentials: 'include',
		}),
	]),
	cache: createCache().restore(window.__APOLLO_STATE__),
});

loadableReady(() => {
	hydrate(
		<ApolloProvider client={client}>
			<IndexView />
		</ApolloProvider>,
		document.getElementById('app')
	);
});
